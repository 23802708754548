import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Modal from '@/components/switchback/Modal';
import { getBookingOrQuoteCostDetails } from '@/redux/selectors/bill/costDetails';

import CostDetails from '../CostDetails/CostDetails';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const CostDetailsModal: React.FC<IProps> = ({ open, onClose }) => {
  const intl = useIntl();
  const details = useSelector(getBookingOrQuoteCostDetails);
  const { costs, total } = details || {};

  if (!total || !costs) {
    return null;
  }

  return (
    <Modal
      title={intl.formatMessage({
        defaultMessage: 'Cost detail',
        id: 'iU0bb0',
      })}
      open={open}
      allowOverlay
      onClose={onClose}
      data-testid="cost-modal">
      <CostDetails costs={costs} total={total} />
    </Modal>
  );
};
