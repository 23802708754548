import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useToast } from '@/components/switchback/Toast/ToastProvider';
import { OccupancyModal } from '@/components/ui/OccupancyModal/OccupancyModal';
import { useRouter } from '@/hooks/useRouter';
import { updateBooking } from '@/redux/modules/checkout';
import { getQuote } from '@/redux/modules/quote';
import { getBookingOrQuote } from '@/redux/selectors/bill/data';
import {
  getBookingOrQuoteOccupancy,
  getBookingOrQuoteSleeps,
} from '@/redux/selectors/bill/occupancy';
import apiRequest, { GENERIC_ERROR_MESSAGE } from '@/services/apiRequest';
import { IOccupancy, IQuote } from '@/services/types/core/quotes';
import { IChangeOccupancyProposal } from '@/services/types/proposals/proposals';
import { formatCurrency } from '@/utility/currency';
import { getCoreApi } from '@/utility/getCoreApi';
import { useThunkDispatch } from '@/utility/redux/dispatch';

import { BillModuleOccupancySelector } from './BillModuleOccupancySelector';

interface IBillModuleProtectionProps {
  onGuestsSelected?: () => void;
}

export const BillModuleOccupancy: React.FC<IBillModuleProtectionProps> = ({ onGuestsSelected }) => {
  const intl = useIntl();
  const router = useRouter();
  const isQuote = !!router.query.quote;
  const { addToast } = useToast();
  const dispatch = useThunkDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const [isSubmittingOccupancyChange, setIsSubmittingOccupancyChange] = useState(false);
  const [nextQuote, setNextQuote] = useState<IQuote | null>(null);
  const [occupancyProposal, setOccupancyProposal] = useState<IChangeOccupancyProposal | null>(null);
  const bookingOrQuote = useSelector(getBookingOrQuote);
  const bookingOrQuoteOccupancy = useSelector(getBookingOrQuoteOccupancy);
  const totalSleeps = useSelector(getBookingOrQuoteSleeps);

  const totalGuests = useMemo(() => {
    if (!bookingOrQuoteOccupancy) {
      return 0;
    }

    return (
      bookingOrQuoteOccupancy.adults +
      bookingOrQuoteOccupancy.children +
      bookingOrQuoteOccupancy.infants
    );
  }, [bookingOrQuoteOccupancy]);

  const totalPets = bookingOrQuote?.pets || 0;

  let footerMessage = '';
  if (occupancyProposal) {
    // proposal only tracks total change, not daily / nightly change
    const totalAdjustedPrice = occupancyProposal.details?.total || 0;

    if (totalAdjustedPrice !== 0) {
      footerMessage = intl.formatMessage(
        {
          defaultMessage:
            'Your total will {isRefund, select, true {decrease} other {increase}} by {price}.',
          id: 'kf2aTu',
        },
        {
          price: formatCurrency({
            currency: bookingOrQuote?.presentment_currency || 'USD',
            digits: 2,
            priceInCents: Math.abs(totalAdjustedPrice),
          }),
          isRefund: totalAdjustedPrice < 0,
        },
      );
    }
  } else if (nextQuote) {
    if (nextQuote.siblings) {
      const adjustedRvPrice = nextQuote.siblings.rental_rv?.calculated_day_price || 0;
      const adjustedCampsitePrice = nextQuote.siblings.rental_campsite?.calculated_day_price || 0;
      const rvPrice = bookingOrQuote?.siblings?.rental_rv?.calculated_day_price || 0;
      const campsitePrice = bookingOrQuote?.siblings?.rental_campsite?.calculated_day_price || 0;
      const items: string[] = [];
      if (adjustedRvPrice !== rvPrice) {
        items.push(
          intl.formatMessage(
            { defaultMessage: 'Your new RV nightly rate will be {price}.', id: 'YBH9Gt' },
            {
              price: formatCurrency({
                currency: bookingOrQuote?.presentment_currency || 'USD',
                digits: 2,
                priceInCents: adjustedRvPrice,
              }),
            },
          ),
        );
      }
      if (adjustedCampsitePrice !== campsitePrice) {
        items.push(
          intl.formatMessage(
            { defaultMessage: 'Your new RV site nightly rate will be {price}.', id: '53kAQB' },
            {
              price: formatCurrency({
                currency: bookingOrQuote?.presentment_currency || 'USD',
                digits: 2,
                priceInCents: adjustedCampsitePrice,
              }),
            },
          ),
        );
      }
      footerMessage = items.join('\n');
    } else {
      const calculatedAdjustedPrice = nextQuote?.calculated_day_price || 0;
      if (calculatedAdjustedPrice !== bookingOrQuote?.calculated_day_price) {
        footerMessage = intl.formatMessage(
          { defaultMessage: 'Your new nightly rate will be {price}.', id: 'JabUYh' },
          {
            price: formatCurrency({
              currency: bookingOrQuote?.presentment_currency || 'USD',
              digits: 2,
              priceInCents: calculatedAdjustedPrice,
            }),
          },
        );
      }
    }
  }

  const handleClose = () => {
    onGuestsSelected?.();
    setIsOpened(false);
  };

  const handleSubmit = async (newOccupancy: IOccupancy, newPets: number) => {
    setIsSubmittingOccupancyChange(true);

    try {
      if (isQuote) {
        // update quote during listing/start of checkout
        await dispatch(
          getQuote({
            guests: newOccupancy,
            pets: newPets,
          }),
        );
      } else if (bookingOrQuote) {
        // patch booking during checkout
        const bookingId = Number(bookingOrQuote.id);
        await dispatch(
          updateBooking(bookingId, {
            occupancy: newOccupancy,
          }),
        );
      }

      onGuestsSelected?.();
      setIsOpened(false);
      setNextQuote(null);
      setOccupancyProposal(null);
    } catch {
      addToast({
        title: intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
        description: GENERIC_ERROR_MESSAGE,
        type: 'TOAST_ERROR',
      });
    } finally {
      setIsSubmittingOccupancyChange(false);
    }
  };

  const handleOccupancyPriceChangeDebounced = debounce(
    async (newOccupancy: IOccupancy, newPets: number) => {
      if (!bookingOrQuote || !newOccupancy) {
        setIsSubmittingOccupancyChange(false);
        return;
      }
      // if the new occupancy would result in no change, do not send a proposal
      if (
        bookingOrQuoteOccupancy?.adults === newOccupancy.adults &&
        bookingOrQuoteOccupancy?.children === newOccupancy.children &&
        bookingOrQuoteOccupancy?.infants === newOccupancy.infants &&
        bookingOrQuote.pets === newPets
      ) {
        setIsSubmittingOccupancyChange(false);
        setNextQuote(null);
        setOccupancyProposal(null);
        return;
      }

      const currency = bookingOrQuote.presentment_currency || 'USD';
      const rentalId = bookingOrQuote.rental_summary?.id;
      const from = bookingOrQuote.from;
      const to = bookingOrQuote.to;

      try {
        let res = null;

        if (isQuote) {
          // with reserve=false we can use the quote as a dry proposal
          // during listing or start of checkout
          const data = {
            presentment_currency: currency,
            rental_id: rentalId,
            from,
            to,
            reserve: false,
            occupancy: newOccupancy,
            delivery_campground_id: (bookingOrQuote as IQuote).delivery_campground_id,
            delivery: {
              stationary: (bookingOrQuote as IQuote).delivery?.stationary,
              location: (bookingOrQuote as IQuote).delivery?.location,
            },
            pets: newPets,
          };

          res = await apiRequest<IQuote>(
            { url: `${getCoreApi()}/quotes`, data, method: 'POST' },
            true,
          );
          setNextQuote(res);
          setOccupancyProposal(null);
        } else if (bookingOrQuote) {
          // during checkout get the changed price with a dry proposal
          res = await apiRequest<IChangeOccupancyProposal>(
            {
              url: `${getCoreApi()}/bookings/${bookingOrQuote.id}/proposals`,
              method: 'POST',
              data: {
                dry_create: true,
                action: 'change_occupancy',
                booking_id: bookingOrQuote.id,
                details: { ...newOccupancy },
              },
            },
            true,
          );
          setOccupancyProposal(res);
          setNextQuote(null);
        }
      } catch {
        addToast({
          title: intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
          description: GENERIC_ERROR_MESSAGE,
          type: 'TOAST_ERROR',
        });
      } finally {
        setIsSubmittingOccupancyChange(false);
      }
    },
    500,
  );

  const handleOccupancyPriceChange = useCallback(
    (newOccupancy: IOccupancy, newPets: number) => {
      setIsSubmittingOccupancyChange(true);
      handleOccupancyPriceChangeDebounced(newOccupancy, newPets);
    },
    [handleOccupancyPriceChangeDebounced],
  );

  return (
    <>
      <BillModuleOccupancySelector
        totalGuests={totalGuests}
        totalPets={totalPets}
        onClick={() => setIsOpened(true)}
      />

      <OccupancyModal
        isOpened={isOpened}
        totalSleeps={totalSleeps}
        totalPets={4} // TODO: get from API
        isSubmittingOccupancyChange={isSubmittingOccupancyChange}
        footerMessage={footerMessage}
        bookingOrQuoteOccupancy={bookingOrQuoteOccupancy}
        bookingOrQuotePets={bookingOrQuote?.pets}
        onClose={handleClose}
        onChangeOccupancy={handleOccupancyPriceChange}
        onSubmit={handleSubmit}
      />
    </>
  );
};
