import React from 'react';
import { useSelector } from 'react-redux';

import PayPalMessages from '@/graphql/components/braintree/PayPalMessages';
import { getBookingOrQuote } from '@/redux/selectors/bill/data';

export const PayLaterOffer: React.FC<{ className?: string }> = ({ className }) => {
  const bookingOrQuote = useSelector(getBookingOrQuote);

  if (bookingOrQuote?.offer_paypal_pay_later) {
    return (
      <PayPalMessages
        amount={((bookingOrQuote?.total || 0) / 100).toString()}
        logoType="inline"
        className={className}
      />
    );
  }

  return null;
};
