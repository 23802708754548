import React, { useRef } from 'react';
import { FormatDateOptions, FormattedDate, FormattedMessage } from 'react-intl';

import DatePickerModal from '@/components/switchback/DatePickerModal/DatePickerModal';
import { ARROW_RIGHT } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import { EBillModuleDatesVariants } from '@/components/ui/BillModule/BillModuleDates/BillModuleDates';
import { BillModuleStepButton } from '@/components/ui/BillModule/BillModuleStepButton/BillModuleStepButton';
import { yieldToMain } from '@/utility/yieldToMain';

interface IProps {
  calendarWarning?: string;
  className?: string;
  disableClear?: boolean;
  disabledDays?: { from: Date; to: Date }[];
  from?: Date;
  isUnavailable?: boolean;
  minimumDays?: number;
  onClearDates?: () => void;
  onConfirmDates: (from: Date, to: Date) => void;
  onDismissCalendar: () => void;
  onOpenCalendar: () => void;
  open?: boolean;
  testId?: string;
  to?: Date;
  variant?: EBillModuleDatesVariants;
}

interface IDatesTextProps {
  to?: Date;
  from?: Date;
  variant?: EBillModuleDatesVariants;
}

const DatesText: React.FC<IDatesTextProps> = ({ from, to, variant }) => {
  if (!from || !to) {
    return (
      <FormattedMessage
        defaultMessage="Select your dates"
        id="P0Dzpv"
        description="Button label for calendar opening in Bill module."
      />
    );
  }

  const formattedDateOptions: FormatDateOptions =
    variant !== EBillModuleDatesVariants.small
      ? {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }
      : {
          day: 'numeric',
          month: 'short',
        };

  return (
    <div className="flex">
      <FormattedDate value={from} {...formattedDateOptions} />
      <Icon className="mx-1 text-gray-700" name={ARROW_RIGHT} size={IconSize.large} />
      <FormattedDate value={to} {...formattedDateOptions} />
    </div>
  );
};

export const BillModuleDates: React.FC<IProps> = ({
  calendarWarning,
  disableClear,
  disabledDays,
  from,
  minimumDays = 0,
  onClearDates,
  onConfirmDates,
  onDismissCalendar,
  onOpenCalendar,
  open = false,
  to,
  variant = EBillModuleDatesVariants.large,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const datesAreSelected = from && to;

  return (
    <>
      {!datesAreSelected && (
        <p className="my-3 font-medium">
          <FormattedMessage defaultMessage="Dates" id="D6b0Au" />
        </p>
      )}

      <div ref={containerRef}>
        <BillModuleStepButton
          testid="departure-date-btn"
          outlined={!datesAreSelected}
          text={<DatesText to={to} from={from} variant={variant} />}
          linkText={
            !datesAreSelected ? <FormattedMessage defaultMessage="Select" id="kQAf2d" /> : undefined
          }
          onClick={() => yieldToMain(onOpenCalendar)}
        />

        <DatePickerModal
          disableClear={disableClear}
          disabledDays={disabledDays}
          initialFrom={from}
          initialTo={to}
          minimumDays={minimumDays}
          onConfirmDates={onConfirmDates}
          onClearDates={onClearDates}
          onDismiss={onDismissCalendar}
          relativeTo={containerRef.current}
          show={open}
          warning={calendarWarning}
        />
      </div>
    </>
  );
};
