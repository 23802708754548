export const disableBodyScroll = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = 'fixed';
};

export const enableBodyScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo?.(0, parseInt(scrollY || '0', 10) * -1);
};
