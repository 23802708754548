import React from 'react';

const FloatingBox = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className = '', ...props }, ref) =>
    children ? (
      <div
        ref={ref}
        className={`${className} absolute inset-0 overflow-y-auto bg-white lg:relative lg:h-auto lg:w-auto lg:min-w-0 lg:min-h-0 lg:rounded-box lg:overflow-visible lg:shadow-200`}
        {...props}
        data-testid="floating-box">
        {children}
      </div>
    ) : null,
);

FloatingBox.displayName = 'FloatingBox';

export default FloatingBox;
