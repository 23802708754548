import React from 'react';

import { ALERT_CIRCLE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

const ErrorMessage: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <div
      aria-live="assertive"
      data-testid="error-message"
      className={`items-center mt-2 text-red-800 inline-grid grid-flow-col gap-1 autoType300 md:mt-0 ${className}`}>
      <Icon name={ALERT_CIRCLE} size={IconSize.small} />
      {children}
    </div>
  );
};

export default ErrorMessage;
