import React, { useState } from 'react';

import { ServiceIntegration } from '@/graphql/types-and-hooks';
import { IBookingService } from '@/services/types/booking/services';

import { BillModuleProtectionSelector } from './BillModuleProtectionSelector';
import { ProtectionModal } from './ProtectionModal';

interface IBillModuleProtectionProps {
  expanded?: boolean;
  onTripProtectionSelected?: () => void;
  availableTripInsuranceService?: ServiceIntegration;
  addedTripInsuranceService?: IBookingService;
  availableRoamlyWeatherInsuranceService?: ServiceIntegration;
  addedRoamlyWeatherInsuranceService?: IBookingService;
}

export const BillModuleProtection: React.FC<IBillModuleProtectionProps> = ({
  expanded = false,
  onTripProtectionSelected,
  availableTripInsuranceService,
  availableRoamlyWeatherInsuranceService,
  addedTripInsuranceService,
  addedRoamlyWeatherInsuranceService,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  if (!availableTripInsuranceService && !availableRoamlyWeatherInsuranceService) {
    return null;
  }

  return (
    <>
      <BillModuleProtectionSelector
        expanded={expanded}
        protectionIsAdded={!!addedTripInsuranceService}
        availableTripInsuranceService={availableTripInsuranceService}
        availableRoamlyWeatherInsuranceService={availableRoamlyWeatherInsuranceService}
        roamlyWeatherisAdded={!!addedRoamlyWeatherInsuranceService}
        onClick={() => setIsOpened(true)}
      />

      <ProtectionModal
        isOpened={isOpened}
        onClose={() => setIsOpened(false)}
        availableTripInsuranceService={availableTripInsuranceService}
        addedTripInsuranceService={addedTripInsuranceService}
        availableRoamlyWeatherInsuranceService={availableRoamlyWeatherInsuranceService}
        addedRoamlyWeatherInsuranceService={addedRoamlyWeatherInsuranceService}
        // Currently, we are not able to determine if a user declined protection from booking object.
        // But we know the renter declined if this step is completed. Otherwise, keep it undecided.
        tripInsuranceServiceIsAdded={!!addedTripInsuranceService || (expanded ? undefined : false)}
        weatherInsuranceServiceIsAdded={
          !!addedRoamlyWeatherInsuranceService || (expanded ? undefined : false)
        }
        onSubmit={onTripProtectionSelected}
      />
    </>
  );
};
