import { OptimizelyFlags, useOptimizelyDecision } from '@/services/experiments';

export default function useFreePeriod() {
  const experimentFreeCancellationDecisionPeriod = useOptimizelyDecision(
    OptimizelyFlags.FREE_CANCELLATION_PERIOD,
  );

  if (
    experimentFreeCancellationDecisionPeriod &&
    experimentFreeCancellationDecisionPeriod.variationKey
  ) {
    return parseInt(experimentFreeCancellationDecisionPeriod.variationKey, 10);
  }

  return null;
}
