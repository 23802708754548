import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BillModuleStepButton } from '@/components/ui/BillModule/BillModuleStepButton/BillModuleStepButton';

interface IBillModuleOccupancySelectorProps {
  totalGuests: number;
  totalPets: number;
  onClick: () => void;
}

export const BillModuleOccupancySelector: React.FC<IBillModuleOccupancySelectorProps> = ({
  totalGuests,
  totalPets,
  onClick,
}) => {
  const intl = useIntl();
  const guestsLabel = intl.formatMessage(
    {
      defaultMessage:
        '{totalGuests, plural, one {# guest} other {# guests}}{totalPets, plural, =0 {} one {, # pet} other {, # pets}}',
      id: 'v3eIdP',
      description: 'Placeholder for bill occupancy step',
    },
    {
      totalGuests,
      totalPets,
    },
  );

  return (
    <BillModuleStepButton
      text={<FormattedMessage defaultMessage="Guests" id="NIVTxd" />}
      linkText={guestsLabel}
      onClick={onClick}
    />
  );
};
