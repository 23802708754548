import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

type TInsuranceBundles = TRootState['insuranceBundles'];

export const getInsuranceBundles = createSelector<TRootState, TInsuranceBundles, TInsuranceBundles>(
  state => state.insuranceBundles,
  insuranceBundles => insuranceBundles,
);
