import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

export const getOwnerName = createSelector<
  TRootState,
  TRootState['listing']['data'],
  string | undefined
>(
  state => state.listing.data,
  listingData => {
    return (
      listingData?.owner.business_name ||
      `${listingData?.owner.first_name} ${listingData?.owner.last_name}`
    );
  },
);
