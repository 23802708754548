import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IOccupancy } from '@/services/types/core/quotes';

const getQuoteOccupancy = createSelector<
  TRootState,
  TRootState['quote']['data'],
  IOccupancy | null
>(
  state => state.quote.data,
  quote => {
    if (!quote || !quote.occupancy) {
      return null;
    }

    return quote.occupancy;
  },
);

const getQuoteSleeps = createSelector<TRootState, TRootState['quote']['data'], number | null>(
  state => state.quote.data,
  quote => {
    if (!quote) {
      return null;
    }

    if (quote.siblings) {
      return Math.min(
        quote.siblings.rental_rv?.rental_summary?.sleeps || 0,
        quote.siblings.rental_campsite?.rental_summary?.campsite_summary?.sleeps || 0,
      );
    }

    return quote.rental_summary?.campsite_summary?.sleeps || 0;
  },
);

const getCheckoutBookingOccupancy = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  IOccupancy | null
>(
  state => state.checkout.booking,
  checkoutBooking => {
    if (!checkoutBooking) {
      return null;
    }

    return {
      adults: checkoutBooking.adults || 0,
      children: checkoutBooking.children || 0,
      infants: checkoutBooking.infants || 0,
    };
  },
);

const getCheckoutBookingSleeps = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  number | null
>(
  state => state.checkout.booking,
  checkoutBooking => {
    if (!checkoutBooking) {
      return null;
    }

    if (checkoutBooking.siblings) {
      return Math.min(
        checkoutBooking.siblings.rental_rv?.rental_summary?.sleeps || 0,
        checkoutBooking.siblings.rental_campsite?.rental_summary?.campsite_summary?.sleeps || 0,
      );
    }

    return checkoutBooking.rental_summary?.campsite_summary?.sleeps || 0;
  },
);

export const getBookingOrQuoteOccupancy = createSelector<
  TRootState,
  IOccupancy | null,
  IOccupancy | null,
  IOccupancy
>(getCheckoutBookingOccupancy, getQuoteOccupancy, (bookingOccupancy, quoteOccupancy) => {
  if (bookingOccupancy) {
    return bookingOccupancy;
  }

  if (quoteOccupancy) {
    return quoteOccupancy;
  }

  return {
    adults: 0,
    children: 0,
    infants: 0,
  };
});

export const getBookingOrQuoteSleeps = createSelector<
  TRootState,
  number | null,
  number | null,
  number
>(getCheckoutBookingSleeps, getQuoteSleeps, (bookingSleeps, quoteSleeps) => {
  if (typeof bookingSleeps === 'number') {
    return bookingSleeps;
  }

  if (typeof quoteSleeps === 'number') {
    return quoteSleeps;
  }

  return 0;
});
