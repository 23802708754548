import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { HELP } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Tooltip from '@/components/switchback/Tooltip/Tooltip';
import { getBookingOrQuoteCurrency } from '@/redux/selectors/checkout/currency';
import { formatCurrency } from '@/utility/currency';

interface IProps {
  securityDeposit?: number | null;
  className?: string;
}

const SecurityDeposit: React.FC<IProps> = ({ securityDeposit, className = '' }) => {
  const intl = useIntl();
  const currency = useSelector(getBookingOrQuoteCurrency);

  if (!securityDeposit) {
    return null;
  }
  const deposit = formatCurrency({
    priceInCents: securityDeposit || 0,
    currency,
    digits: 2,
  });

  const securityDepositMsg = intl.formatMessage({
    defaultMessage: 'Security deposit (refundable)',
    id: 'AkoJ7T',
  });

  return (
    <div className={`flex items-center pt-2 my-2 text-gray-900 text-500 autoType600 ${className}`}>
      <span>{securityDepositMsg} </span>
      <span className="inline-flex items-center justify-center w-5 h-5 ml-1 border-none rounded-full text-gray-850 bg-canvas-200 transition-all duration-500 highlight hover:bg-gray-850 hover:text-white group-focus:bg-gray-850 group-focus:text-white">
        <Tooltip
          content={intl.formatMessage(
            {
              defaultMessage: `2 days before your trip begins:{br} A hold goes on your payment method for the security deposit amount.{br} {br} Within 7 days after your trip ends:{br} The hold releases, so long as everything’s okay with the property.`,
              id: 'EeIXRS',
            },
            { br: <br /> },
          )}
          label={intl.formatMessage({
            defaultMessage: 'Security deposit info',
            id: 'XDht0k',
            description:
              'Message displayed to screen reader users to let them know there is a tooltip to access',
          })}>
          <Icon name={HELP} size={IconSize.normal} />
        </Tooltip>
      </span>
      <span className="ml-auto">{deposit}</span>
    </div>
  );
};
export default SecurityDeposit;
