import React from 'react';
import { useIntl } from 'react-intl';

import Help from '@/components/switchback/Help/Help';
import Text from '@/components/switchback/Text/Text';

interface ICost {
  title?: string;
  name: string;
  description?: string;
  info?: string;
  value: string;
  divider?: boolean;
  isDiscount?: boolean;
}

interface IProps {
  costs: ICost[];
  total: string;
}

const CostDetails: React.FC<IProps> = ({ costs, total }) => {
  const intl = useIntl();

  return (
    <table className="w-full table-auto">
      <thead className="sr-only">
        <tr>
          <th scope="col">
            {intl.formatMessage({
              defaultMessage: 'Description',
              id: 'FQtpSz',
              description: 'Description column title on cost details table',
            })}
          </th>
          <th scope="col">
            {intl.formatMessage({
              defaultMessage: 'Cost',
              id: 'GfNM0C',
              description: 'Cost column title on cost details table',
            })}
          </th>
        </tr>
      </thead>
      <tbody>
        {costs.map(({ title, name, description, info, value, divider, isDiscount }, idx, arr) => (
          <React.Fragment key={name ? name : 'cost-details-' + idx}>
            {(title || divider) && (
              <tr className="text-left highlight autoType400">
                <th colSpan={2} scope="colgroup">
                  <span
                    className={`block w-full pt-6 mt-6 border-t border-gray-200 ${
                      title ? 'pb-1' : ''
                    }`}>
                    {title ? title : null}
                  </span>
                </th>
              </tr>
            )}

            {name && (
              <tr className="align-top">
                <th
                  scope="row"
                  className={`py-1 pr-4 font-normal text-left ${
                    idx === arr.length - 1 ? 'pb-6' : ''
                  }`}>
                  <span className="flex">
                    <Text type="paragraph" className={isDiscount ? 'text-gray-850' : ''}>
                      {name}
                    </Text>
                    {info && (
                      <p className="flex items-center ml-1">
                        <Help label={name} content={info} />
                      </p>
                    )}
                  </span>
                  {description && (
                    <Text type="block" className="text-gray-500 whitespace-pre-wrap autoType200">
                      {description}
                    </Text>
                  )}
                </th>
                <td className="py-1 text-right">
                  {!isDiscount ? (
                    <Text type="inline" className={idx === arr.length - 1 ? 'pb-6' : ''}>
                      {value}
                    </Text>
                  ) : (
                    <Text
                      type="inline"
                      className={idx === arr.length - 1 ? 'pb-6 text-gray-850' : 'text-gray-850'}>
                      -{value}
                    </Text>
                  )}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot className="border-t border-gray-200">
        <tr className="align-top">
          <th scope="row" className="py-6 text-left">
            <Text type="inline" className="highlight autoType600">
              {intl.formatMessage({
                defaultMessage: 'Total cost',
                id: 'BP+63j',
              })}
            </Text>
          </th>
          <td className="py-6 text-right">
            <Text type="inline" className="highlight autoType600">
              {total}
            </Text>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default CostDetails;
