import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ErrorMessage from '@/components/switchback/ErrorMessage/ErrorMessage';
import { TRootState } from '@/redux/rootReducer';
import {
  getBookingOrQuoteAddons,
  getOneTimeCheckoutBookingItems,
} from '@/redux/selectors/bill/addons';
import { getBookingOrQuoteDates } from '@/redux/selectors/bill/dates';
import { getCurrency } from '@/redux/selectors/currency';
import { getAddonsError, getAddonsIsLoading } from '@/redux/selectors/listing/addons';
import { getRentalItems } from '@/redux/selectors/rentalItems';
import { IAddOnItem } from '@/services/types/quote/IAddons';
import { formatCurrency } from '@/utility/currency';

interface IFooterProps {
  onSubmit: () => void;
  selectedAddons: IAddOnItem[] | undefined;
}

const Footer: React.FC<IFooterProps> = ({ onSubmit, selectedAddons }) => {
  const intl = useIntl();
  const currency = useSelector(getCurrency);
  const isLoading = useSelector(getAddonsIsLoading);
  const { from, to } = useSelector(getBookingOrQuoteDates);
  const hasDatesSelected = from && to;
  const originalAddons = useSelector(getBookingOrQuoteAddons);
  const rentalItems = useSelector(getRentalItems);
  const oneTimeItems = useSelector(getOneTimeCheckoutBookingItems);

  const hours = dayjs(to).diff(dayjs(from), 'hours');
  const days = Math.floor(hours / 24);

  const bookingAddonsError = useSelector<TRootState, string | undefined>(
    state => state.checkout.addonsError?.error,
  );
  const error = useSelector(getAddonsError);

  const errorContent = !hasDatesSelected
    ? intl.formatMessage({
        defaultMessage: 'Add dates to see total cost',
        id: '5jKIlP',
        description: 'Add-Ons Modal - Error message if user has not added dates yet',
      })
    : error || bookingAddonsError;

  //null when there is no update, true when added or removed, false when only item count change
  const updateStatus = useMemo(() => {
    let hasUpdatedAddon: boolean | null = null;
    if ((selectedAddons || []).length < (originalAddons || [])?.length) {
      hasUpdatedAddon = true;
    } else {
      for (const addon of selectedAddons || []) {
        const duplicated = originalAddons?.find(a => a.id === addon.id);
        if (duplicated) {
          if (duplicated.count !== addon.count) {
            hasUpdatedAddon = false;
            break;
          }
        } else {
          hasUpdatedAddon = true;
          break;
        }
      }
    }
    return hasUpdatedAddon;
  }, [selectedAddons, originalAddons]);

  const totalPrice = useMemo(() => {
    return selectedAddons?.reduce((acc, addon) => {
      const addonItem = rentalItems.find(item => item.id === addon.id);
      const oneTimeItem = oneTimeItems?.find(item => item.id === (addon.id || addon.itemId));

      if (addonItem && !addonItem.deferred && addon?.count) {
        const price = addonItem.price * addon.count;
        if (addonItem.daily) {
          // use days as opposed to duration since add ons are only applied
          // for days rented regardless of the minimum days on a listing
          return acc + price * days;
        }

        return acc + price;
      } else if (oneTimeItem && !oneTimeItem?.deferred && addon?.count) {
        const price = (oneTimeItem?.rawPrice || 0) * addon.count;
        if (oneTimeItem.daily) {
          return acc + price * days;
        }

        return acc + price;
      } else {
        return acc + 0;
      }
    }, 0);
  }, [selectedAddons, rentalItems, days, oneTimeItems]);

  let buttonLabel = '';
  if (!updateStatus) {
    buttonLabel =
      updateStatus === null
        ? intl.formatMessage({
            defaultMessage: 'Upgrade',
            id: 'O044zD',
            description: 'Addons Modal Footer - Button Text',
          })
        : intl.formatMessage({
            defaultMessage: 'Add to your booking',
            id: 'EnEOa2',
            description: 'Addons Modal Footer - Button Text',
          });
  } else {
    buttonLabel = intl.formatMessage({
      defaultMessage: 'Update your booking',
      id: '6grPQK',
      description: 'Addons Modal Footer - Button Text',
    });
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 pt-7">
      <span className="relative highlight autoType600">
        <span data-testid="add-ons-price" aria-live="polite" className="flex items-center">
          <FormattedMessage
            defaultMessage="Total:"
            id="ylJz+J"
            description="Addons Modal Footer - Total value"
          />
          {errorContent ? (
            <ErrorMessage className="px-3">
              <span className="autoType200 text">{errorContent}</span>
            </ErrorMessage>
          ) : (
            <span>{formatCurrency({ priceInCents: totalPrice, currency, digits: 2 })}</span>
          )}
        </span>
      </span>
      <Button
        className="whitespace-nowrap"
        onClick={onSubmit}
        loading={isLoading}
        variant={EButtonColorVariant.Primary}
        label={buttonLabel}
        disabled={updateStatus === null}
        data-testid="add-to-booking-btn"
      />
    </div>
  );
};

export default Footer;
