import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { BillModuleStepButton } from '@/components/ui/BillModule/BillModuleStepButton/BillModuleStepButton';
import { ServiceIntegration } from '@/graphql/types-and-hooks';
import { getAvailableServicesLoading } from '@/redux/selectors/availableServices';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';

interface IBillModuleProtectionSelectorProps {
  expanded: boolean;
  protectionIsAdded: boolean;
  availableTripInsuranceService?: ServiceIntegration;
  availableRoamlyWeatherInsuranceService?: ServiceIntegration;
  roamlyWeatherisAdded?: boolean;
  onClick: () => void;
}

export const BillModuleProtectionSelector: React.FC<IBillModuleProtectionSelectorProps> = ({
  expanded,
  protectionIsAdded,
  availableTripInsuranceService,
  availableRoamlyWeatherInsuranceService,
  roamlyWeatherisAdded,
  onClick,
}) => {
  const weatherInsuranceExperimentDecision = useExperimentIsEnabled(
    OptimizelyFlags.ROAMLY_WEATHER_PRE_PURCHASE,
  );

  const availableServicesLoading = useSelector(getAvailableServicesLoading);

  const availablePlans =
    weatherInsuranceExperimentDecision &&
    availableTripInsuranceService &&
    availableRoamlyWeatherInsuranceService
      ? 2
      : 1;

  const protectionStatus = roamlyWeatherisAdded && protectionIsAdded ? 2 : 1;

  if (expanded) {
    return (
      <>
        <p className="my-3 font-medium">
          <FormattedMessage defaultMessage="Trip protection" id="Si9jYU" />
        </p>

        <div>
          <BillModuleStepButton
            outlined
            loading={weatherInsuranceExperimentDecision && availableServicesLoading}
            text={
              <FormattedMessage
                defaultMessage="{numAvailablePlans} {numAvailablePlans, plural, one {plan} other {plans}} available"
                id="Zp8otJ"
                values={{
                  numAvailablePlans: availablePlans,
                }}
              />
            }
            linkText={<FormattedMessage defaultMessage="Select" id="kQAf2d" />}
            onClick={onClick}
          />
        </div>
      </>
    );
  }

  return (
    <BillModuleStepButton
      text={
        weatherInsuranceExperimentDecision ? (
          <FormattedMessage defaultMessage="Protect your trip" id="903/wm" />
        ) : (
          <FormattedMessage defaultMessage="Trip protection" id="Si9jYU" />
        )
      }
      linkText={
        protectionIsAdded || roamlyWeatherisAdded ? (
          <>
            {weatherInsuranceExperimentDecision ? (
              <FormattedMessage
                defaultMessage="{protectionStatus} selected"
                id="8N6IiE"
                values={{ protectionStatus }}
              />
            ) : (
              <FormattedMessage defaultMessage="Added" id="rIAJlE" />
            )}
          </>
        ) : (
          <FormattedMessage defaultMessage="Declined" id="uRYIb9" />
        )
      }
      onClick={onClick}
    />
  );
};
