import React, { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Text from '@/components/switchback/Text/Text';
import { useToast } from '@/components/switchback/Toast/ToastProvider';
import { BillModuleStepButton } from '@/components/ui/BillModule/BillModuleStepButton/BillModuleStepButton';
import InsuranceModal from '@/components/ui/InsuranceModal/InsuranceModal';
import { updateBookingBundle } from '@/redux/modules/checkout';
import { getQuote } from '@/redux/modules/quote';
import {
  getBookingOrQuoteCurrency,
  getBookingOrQuoteIsLoading,
  getBookingOrQuoteStationaryDelivery,
} from '@/redux/selectors/bill/data';
import { getBillSummary } from '@/redux/selectors/bill/summary';
import { getBookingData } from '@/redux/selectors/checkout/booking';
import { getInsuranceBundles } from '@/redux/selectors/listing/insuranceBundles';
import { getQuoteData } from '@/redux/selectors/quote';

type TBillModuleInsuranceProps = {
  updateStep?: (bundleId?: string) => void;
  completed?: boolean;
};

const BillModuleInsuranceSelector = ({
  completed,
  handleChangeInsurance,
  selectedBundleName,
  selectedBundleDescription,
  omitCaret,
}: {
  completed?: boolean;
  selectedBundleName?: ReactNode;
  selectedBundleDescription?: ReactNode;
  handleChangeInsurance: () => void;
  omitCaret?: boolean;
}) => {
  if (completed) {
    return (
      <>
        <BillModuleStepButton
          outlined={false}
          text={<FormattedMessage defaultMessage="Insurance coverage" id="yAfzwK" />}
          linkText={selectedBundleName}
          onClick={handleChangeInsurance}
          omitCaret={omitCaret}
        />

        {selectedBundleDescription && (
          <Text className="italic text-gray-500 whitespace-pre-line autoType300" type="inline">
            {selectedBundleDescription}
          </Text>
        )}
      </>
    );
  }

  return (
    <>
      <p className="my-3 font-medium">
        <FormattedMessage defaultMessage="Insurance coverage" id="yAfzwK" />
        <span className="pl-1 text-base font-thin">
          (<FormattedMessage defaultMessage="required" id="+gRnOa" />)
        </span>
      </p>

      <div>
        <BillModuleStepButton
          outlined={true}
          text={<FormattedMessage defaultMessage="3 Plans available" id="lQf3GH" />}
          linkText={<FormattedMessage defaultMessage="Select" id="kQAf2d" />}
          onClick={handleChangeInsurance}
        />
      </div>
    </>
  );
};

export const BillModuleInsurance: React.FC<TBillModuleInsuranceProps> = ({
  updateStep,
  completed = true,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const booking = useSelector(getBookingData);
  const summary = useSelector(getBillSummary);
  const baseInsuranceBundles = useSelector(getInsuranceBundles);
  const currency = useSelector(getBookingOrQuoteCurrency);
  const stationaryDelivery = useSelector(getBookingOrQuoteStationaryDelivery);
  const isLoading = useSelector(getBookingOrQuoteIsLoading);

  const [isInsuranceOpen, setIsInsuranceOpen] = useState(false);
  const quote = useSelector(getQuoteData);

  const openInsuranceModal = () => setIsInsuranceOpen(true);
  const closeInsuranceModal = () => setIsInsuranceOpen(false);

  const handleSubmitInsurance = async (bundleId: string) => {
    try {
      if (booking) {
        await dispatch(updateBookingBundle(booking.id, bundleId));
      } else {
        await dispatch(getQuote({ bundleId }));
      }
    } catch {
      addToast({
        title: intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
        description: intl.formatMessage({
          defaultMessage: 'Your recent change is still processing. Try again in a minute or so.',
          id: 'Kk5UWs',
        }),
        type: 'TOAST_ERROR',
      });
    }

    updateStep?.(bundleId);
  };

  const userNoticeEvents = booking?.user_notice_events || quote?.user_notice_events;

  return (
    <>
      <BillModuleInsuranceSelector
        completed={completed}
        handleChangeInsurance={openInsuranceModal}
        selectedBundleName={
          summary?.insuranceValue
            ? baseInsuranceBundles.find(b => b.id === summary?.insuranceBundleId)?.name
            : summary?.waiverSummary && (
                <FormattedMessage
                  defaultMessage="Not covered"
                  id="7u+9A0"
                  description="Label for insurance not covered"
                />
              )
        }
        selectedBundleDescription={!summary?.insuranceValue && summary?.waiverSummary?.description}
        omitCaret={!baseInsuranceBundles?.length}
      />

      {!!baseInsuranceBundles?.length && (
        <InsuranceModal
          userNoticeEvents={userNoticeEvents}
          selectedBundleId={summary?.insuranceBundleId}
          insuranceBundles={baseInsuranceBundles}
          currency={currency}
          open={isInsuranceOpen}
          onSubmit={handleSubmitInsurance}
          onClose={closeInsuranceModal}
          stationaryDelivery={stationaryDelivery}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
