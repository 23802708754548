import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  onSubmit: () => void;
  isLoading?: boolean;
}

const InsuranceModalFooter: React.FC<IProps> = ({ onSubmit, isLoading }) => (
  <div className="flex justify-end">
    <Button
      data-testid="insurance-modal-submit-button"
      onClick={onSubmit}
      loading={isLoading}
      variant={EButtonColorVariant.Primary}
      label={
        <FormattedMessage
          defaultMessage="Confirm"
          id="Y9bGF0"
          description="UI > Insurance Modal > Insurance Modal Footer"
        />
      }
    />
  </div>
);

export default InsuranceModalFooter;
