import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '@/components/switchback/Modal';
import { setAddons, setTotalAddons } from '@/redux/modules/addons';
import { updateBookingAddons } from '@/redux/modules/checkout';
import {
  getBookingOrQuoteAddons,
  getOneTimeCheckoutBookingItems,
} from '@/redux/selectors/bill/addons';
import { getBookingId } from '@/redux/selectors/checkout/booking';
import {
  getAddons,
  getAddonsWithQuantitySelected,
  getHasAddons,
} from '@/redux/selectors/listing/addons';
import { IAddOnItem } from '@/services/types/quote/IAddons';

import AddOnsList from '../AddOnsList/AddOnsList';
import Footer from './Footer';

interface IProps {
  isModalOpen?: boolean;
  onCloseModal: () => void;
  onOpenModal: () => void;
}

const AddOnsModal: React.FC<IProps> = ({ isModalOpen = false, onCloseModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const hasAddons = useSelector(getHasAddons);
  const oneTimeItems = useSelector(getOneTimeCheckoutBookingItems);
  const availableItems = useSelector(getAddonsWithQuantitySelected);
  const addons = useSelector(getAddons);
  const bookingAddons = useSelector(getBookingOrQuoteAddons);
  const bookingId = useSelector(getBookingId);
  const [selectedAddons, setSelectedAddons] = useState(bookingId ? bookingAddons : addons);

  const availableItemsData = [...availableItems, ...(oneTimeItems || [])];

  useEffect(() => {
    if (isModalOpen) {
      setSelectedAddons(bookingId ? bookingAddons : addons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleSubmit = () => {
    if (bookingId) {
      dispatch(setAddons(selectedAddons));
      dispatch(setTotalAddons());
      dispatch(updateBookingAddons());
    } else {
      dispatch(setAddons(selectedAddons));
      dispatch(setTotalAddons());
    }
    onCloseModal();
  };

  const handleAddonUpdate = (addon: IAddOnItem) => {
    const currentAddons = selectedAddons ? [...selectedAddons] : [];
    const addonIndex = currentAddons.findIndex(item => (item.id || item.itemId) === addon.id);
    if (addonIndex !== -1) {
      if (addon.count) {
        currentAddons[addonIndex] = addon;
      } else {
        currentAddons.splice(addonIndex, 1);
      }
    } else if (addon.count) {
      currentAddons.push(addon);
    }
    setSelectedAddons(currentAddons);
  };

  if (!hasAddons && (!oneTimeItems || oneTimeItems?.length === 0)) {
    return null;
  }

  return (
    <Modal
      title={intl.formatMessage({
        defaultMessage: 'Trip add-ons',
        id: 'NtEZy+',
        description: 'AddOns - Modal Title',
      })}
      footer={<Footer onSubmit={handleSubmit} selectedAddons={selectedAddons} />}
      onClose={onCloseModal}
      open={isModalOpen}
      data-testid="addon-modal">
      <div className="mt-4 md:pb-0">
        <AddOnsList
          availableItems={availableItemsData}
          onAddonChange={handleAddonUpdate}
          selectedItems={selectedAddons}
        />
      </div>
    </Modal>
  );
};

export default AddOnsModal;
