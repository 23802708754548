import { createSelector } from 'reselect';

import { TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';

import { getUserCurrency } from '../currency';

export const getBookingOrQuoteCurrency = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['quote']['data'],
  TCurrency,
  TCurrency
>(
  state => state.checkout.booking,
  state => state.quote.data,
  getUserCurrency,
  (booking, quote, userCurrency) =>
    booking?.presentment_currency ||
    booking?.locale.base_currency ||
    quote?.presentment_currency ||
    userCurrency,
);
