import React from 'react';

import Divider from '@/components/switchback/Divider/Divider';

interface IBillSectionStepProps {
  showDivider?: boolean;
}

export const BillSectionStep: React.FC<React.PropsWithChildren<IBillSectionStepProps>> = ({
  showDivider = true,
  children,
}) => {
  return (
    <>
      <div className="my-3">{children}</div>
      {showDivider && <Divider />}
    </>
  );
};
